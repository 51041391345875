
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate  } from 'react-router-dom';
// import Typography from '@mui/material/Typography';
// import StormIcon from '@mui/icons-material/Storm';
import MenuIcon from '@mui/icons-material/Menu';
import { alpha, useTheme } from '@mui/material/styles';

// import CustomButton from '../components/CustomButton';
import { styled } from '@mui/material/styles';
import Tabs from "../components/com/tabs";

interface HeaderProps {
  onSidebarOpen: () => void;
  color: 'transparent' | 'primary' | 'secondary' | 'inherit';
}

const Header = ({ onSidebarOpen,color = 'inherit' }: HeaderProps): JSX.Element => {
  const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    // border: '1px solid',
    borderRadius:'5px',
    lineHeight: 1.5,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const rouetHandler = () => {
    navigate('/contactus');
  }

  return (
    <>
      <AppBar
        color={color}
        position='fixed'
        sx={{
          border: 0,
          padding: '0px 0',
          top: 'auto',
          boxShadow:
            '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
        }}
        id='header'
      >
        <Toolbar sx={{ minHeight: 70 }}>
          <Link href='/' sx={{ textDecoration: 'none' }}>
            <IconButton size='large' disabled>
              <div>
                <a href="#">
                  <img src="/yfjs-logo-w.png" alt="YFJS Logo" className='yfjs-logo' />
                </a>
              </div>
            </IconButton>
          </Link>
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Box
            sx={{
              alignItems: 'center',
              display: { lg: 'flex', md: 'none', xs: 'none' },
            }}
          >
            <Tabs/>
            {/* <CustomButton href='#products' text='产品服务' />
            <CustomButton href='#solutions' text='解决方案' />
            <CustomButton href='#customers' text='客户案例' />
            <CustomButton href='#about' text='关于我们' /> */}
            {/* <CustomButton href='#contact' text='联系我们' /> */}
          </Box>
          <Box sx={{ flexShrink:0,marginLeft:'auto', display: { lg: 'flex' }, justifyContent: 'flex-end' }}>
            <BootstrapButton variant="contained" onClick={()=>rouetHandler()}>
                联系我们
            </BootstrapButton>
             {/* <Button variant="contained" onClick={()=>rouetHandler()}>联系我们</Button> */}
          </Box>
          <Box
            sx={{
              marginLeft: { lg: 'auto', md: 'auto', xs: 'auto' },
              display: { md: 'block', lg: 'none' },
            }}
            alignItems='center'
          >
            <Button
              onClick={() => onSidebarOpen()}
              aria-label='Menu'
              variant='outlined'
              sx={{
                borderRadius: 0,
                minWidth: 'auto',
                padding: 1,
                borderColor: alpha(theme.palette.divider, 0.2),
              }}
            >
              <MenuIcon sx={{ color: 'white' }}/>
            </Button>
          </Box>
          
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
