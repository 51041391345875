import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './designfarm.scss'
const About = () => {
  const designFarm = window.config.designFarm || {}
  const navigate = useNavigate();
  const routerHandler = () => {
    navigate('/contactus');
  }
  return (
    <div id='designfarm'>
      <div className='sheepfarm-image'>
        <img src='/screen/homedesign1.png' alt='screnn-home' />
        <div className='sheepfarm-image-position'>
          <Box className='sheepfarm-title' sx={{
            fontSize: {
              lg: '36px',
              xl: '36px'
            }
          }}>{designFarm.title}</Box>
          <Box className='sheepfarm-text' sx={{
            padding: '0px 10%',
          }}>{designFarm.subTitle}</Box>
          <Button className='sheepfarm-button cusor-point' sx={{
            color: '#fff',
            padding: {
              md: "10px 150px",
              xs: "8px 16px",
            }
          }} onClick={() => { routerHandler() }}>申请体验</Button>
        </div>
      </div>
      <div className='yfjs-title-white sheepfarm-contaner'>
        {designFarm.contentTitle}
      </div>
      {
        designFarm.content&&designFarm.content.map((i,index)=>{
          return <div className='sheepfarm-show' key={index}>
          <div className='yfjs-title-white-16'>
            {i.title}
          </div>
          <ul>
            {
              i.option&&i.option.map((j,jindex)=>{
                return <li key={jindex}>
                {j}
              </li>
              })
            }
          </ul>
        </div>
        })
      }
    </div>

  );
};

export default About;
