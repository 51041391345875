import React, {  useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CustomButton from '../CustomButton'
import '../../scss/tabs.scss'

export default function App() {
    const navigate = useNavigate();
    const [isShow,setIsShow] = useState(0)
    const location = useLocation();
    const activeTab = location.pathname
    const clickHandler = (res)=>{
        setIsShow(res)
    }
    const leaveHandler = ()=>{
        setIsShow(0)
    }

    const routeHandler = (id) => {
          navigate(id)
    }
  return (
    <div className='header-tabs'>
        <div className='header-tab' onMouseEnter={()=>clickHandler(1)} onMouseLeave={()=>leaveHandler()}>
          <CustomButton href="#products" text="产品服务" classname={(activeTab === '/datasit'||activeTab === '/screen' )? 'active' : ''}  onClick={()=>routeHandler('/datasit')}/>
          <div className={isShow === 1 ? 'header-tab-position':'header-tab-position disply-none'}>
          <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler('/datasit')}>
                <img src='/children/header1.png' alt='1'/>
                <p  className='yfjs-text-bold' style={{color:activeTab === '/datasit'?'blue':''}}>低代码开发平台</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler('/screen')}>
                <img src='/children/header2.png' alt='1'/>
                <p  className='yfjs-text-bold' style={{color:activeTab === '/screen'?'blue':''}}>展示大屏速搭平台</p>
            </div>
          </div>
        </div>
        <div className='header-tab' onMouseEnter={()=>clickHandler(2)} onMouseLeave={()=>leaveHandler()}>
          <CustomButton href="#solutions" text="解决方案" classname={(activeTab === '/sheep'||activeTab === '/design'||activeTab === '/fire' )? 'active' : ''}  onClick={()=>routeHandler('/sheep')}/>
          <div className={isShow === 2 ? 'header-tab-position':'header-tab-position disply-none'}>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler('/sheep')}>
                <img src='/children/header7.png' alt='1'/>
                <p  className='yfjs-text-bold' style={{color:activeTab === '/sheep'?'blue':''}}>智慧畜牧管理解决方案</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler('/design')}>
                <img src='/children/header9.png' alt='1'/>
                <p  className='yfjs-text-bold' style={{color:activeTab === '/design'?'blue':''}}>数字化线束设计解决方案</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler('/fire')}>
                <img src='/children/header8.png' alt='1'/>
                <p  className='yfjs-text-bold' style={{color:activeTab === '/fire'?'blue':''}}>智慧消防一体化解决方案</p>
            </div>
            
            
          </div>
        </div>
        <div className='header-tab' onMouseEnter={()=>clickHandler(3)} onMouseLeave={()=>leaveHandler()}>
        <CustomButton href="#customers" text="客户案例" classname={(activeTab === '/sheefarm'||activeTab === '/designfarm'||activeTab === '/firefarm'  )? 'active' : ''}  onClick={()=>routeHandler('/sheefarm')}/>
        <div className={isShow === 3 ? 'header-tab-position':'header-tab-position disply-none'}>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler('/sheefarm')}>
                <img src='/children/header4.png' alt='1'/>
                <p  className='yfjs-text-bold' style={{color:activeTab === '/sheefarm'?'blue':''}}>新疆某牧业有限责任公司</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler('/designfarm')}>
                <img src='/children/header5.png' alt='1'/>
                <p  className='yfjs-text-bold' style={{color:activeTab === '/designfarm'?'blue':''}}>某空天行业设计研究院</p>
            </div>
            <div className='header-tab-position-image cusor-point'  onClick={()=>routeHandler('/firefarm')}>
                <img src='/children/header6.png' alt='1'/>
                <p  className='yfjs-text-bold' style={{color:activeTab === '/firefarm'?'blue':''}}>应急管理部某消防研究所</p>
            </div>
          </div>
        </div>
        <div className='header-tab'  onClick={()=>routeHandler('/contact')}>
          <CustomButton href="#about" text="关于我们" classname={(activeTab === '/contact' )? 'active' : ''}/>
        </div>
        <div className='header-tab' onClick={()=>routeHandler('/join')}>
            <CustomButton  text="人才招聘" classname={(activeTab === '/join' )? 'active' : ''}/>
        </div>
    </div>
  );
}