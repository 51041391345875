// import Hero from '../components/Hero';
import Products from '../components/Products';
import Image from '../components/Image';
import Solutions from '../components/Solutions';
import Customers from '../components/Customers';
import About from '../components/About';
import Partner from '../components/partner';
// import Contact from '../components/Contact';
const Home = (): JSX.Element => {
  return (
    <div id='home'>
      <Image/>
      <Products />
      <Solutions />
      <Customers />
      <About />
      <Partner/>
      {/* <Contact /> */}
    </div>
  );
};

export default Home;
