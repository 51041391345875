import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import api from '../../tests/apiurl'
import { styled } from '@mui/system';
export default function MyForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comName, setComName] = useState('');
    const [describe, setDescribe] = useState('');

    const [open, setOpen] = useState(false); // 控制 Snackbar 的显示
    const [message, setMessage] = useState(''); // Snackbar 显示的消息
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        // 在这里处理表单提交逻辑，例如发送到服务器等
        const obj = { name, email, phone, comName, describe }
        axios
            .post(api.baseurl + '/api/www/contact/add', obj, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                if (response.data.code === 200) {
                    setMessage('提交成功！'); // 设置成功消息
                    setOpen(true); // 显示 Snackbar

                } else {
                    setMessage('提交失败，请重试。'); // 设置失败消息
                    setOpen(true); // 显示 Snackbar
                }
            })
            .catch((error) => console.log(error));
    };
    const StyledButton = styled(Button)({
        backgroundColor: '#0063cc',
        marginTop: '60px',
        color: 'white',
        // padding: '15px 150px',
        width:'50%',
        textAlign:'center',
        '&:hover': {
            backgroundColor: '#0069d9',
        },
    });

    const inputProps = {
        style: { color: 'black', background: 'white' }, // 设置输入框文本颜色和背景色
    };

    const inputLabelProps = {
        style: { color: 'black' }, // 设置输入框标签文本颜色
    };

    const handleClose = () => {
        setOpen(false); // 关闭 Snackbar
    };
    const validateEmail = (value) => {
        // 邮箱验证规则
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(value) && value.endsWith('.com');
        setEmailError(isValid ? '' : '请输入有效的 .com 邮箱');
    };

    const validatePhone = (value) => {
        // 手机号验证规则
        const phoneRegex = /^1\d{10}$/;
        const isValid = phoneRegex.test(value);
        setPhoneError(isValid ? '' : '请输入以 1 开头的 11 位手机号码');
    };


    return (
        <form onSubmit={handleSubmit}>
           
            <Grid container spacing={10}>
                <Grid item md={6} xs={12}>
                    <div style={{marginBottom:'10px'}}>姓名 <span style={{color:'red'}}>*</span></div>
                    <TextField
                        label=""
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        fullWidth
                        variant="outlined"
                        InputProps={inputProps}
                        InputLabelProps={inputLabelProps}
                    />
                </Grid>
                <Grid item  md={6} xs={12}>
                <div style={{marginBottom:'10px'}}>邮箱 <span style={{color:'red'}}>*</span></div>
                    <TextField
                        label=""
                        type="email"
                        value={email}
                        onChange={(e) => {
                            const value = e.target.value;
                            setEmail(value);
                            validateEmail(value);
                        }}
                        required
                        fullWidth
                        variant="outlined"
                        InputProps={inputProps}
                        InputLabelProps={inputLabelProps}
                        error={!!emailError}
                    helperText={emailError}

                    />
                </Grid>
                <Grid item  md={6} xs={12}>
                <div style={{marginBottom:'10px'}}>手机号码 <span style={{color:'red'}}>*</span></div>
                    <TextField
                        label=""
                        type="tel"
                        value={phone}
                        onChange={(e) => {
                            const value = e.target.value;
                            setPhone(value);
                            validatePhone(value);
                        }}
                        required
                        fullWidth
                        variant="outlined"
                        InputProps={inputProps}
                        InputLabelProps={inputLabelProps}
                        error={!!phoneError}
                    helperText={phoneError}

                    />
                </Grid>
                <Grid item  md={6} xs={12}>
                <div style={{marginBottom:'10px'}}>公司名称 <span style={{color:'red'}}>*</span></div>
                    <TextField
                        label=""
                        type="comName"
                        value={comName}
                        onChange={(e) => setComName(e.target.value)}
                        required
                        fullWidth
                        variant="outlined"
                        InputProps={inputProps}
                        InputLabelProps={inputLabelProps}

                    />
                </Grid>
                <Grid item xs={12}>
                <div style={{marginBottom:'10px'}}>需求简述</div>
                    <TextField
                        label=""
                        type="describe"
                        rows={8}
                        value={describe}
                        onChange={(e) => setDescribe(e.target.value)}
                        fullWidth
                        multiline
                        variant="outlined"
                        InputProps={inputProps}
                        InputLabelProps={inputLabelProps}
                    />
                </Grid>
            </Grid>
            <div className='yfjs-display'>
                <StyledButton
                    type="submit"
                    variant="contained"
                    sx={{ background: '#1D4ED8',border:'2px solid #3B82F6',fontWeight:'normal'}}
                >
                    发送信息
                </StyledButton>

            </div>

            <Snackbar
                open={open}
                autoHideDuration={6000} // 6秒后自动关闭
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                  position: 'fixed',
                  bottom: '50%',
                  left: '50%',
                  transform: 'translate(-50%, 50%)',
                  zIndex: 1300, // 确保在其他内容之上
                }}
            >
                <Alert onClose={handleClose} severity="success">
                    {message}
                </Alert>
            </Snackbar>
            
        </form>
    );
};
