import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './design.scss'
const About = () => {
  const design = window.config.design || {}
  const navigate = useNavigate();
  const routerHandler = () => {
    navigate('/contactus');
  }
  return (
    <div id='design'>
      <div className='sheep-image'>
        <img src='/screen/homedesign.png' alt='screnn-home' />
        <Box className='sheep-image-position'
        >
          <p className='sheep-image-position-p'>{design.title}</p>
          <div className='sheep-image-padding'>
            {design.subTitle}
          </div>
        </Box>
      </div>
      <div className='sheep-show-top'>{design.contentTitle}</div>
      <div className='sheep-show'>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12} md={5}>
            <div className='sheep-show-left'>
               <img src={design.contentImage?design.contentImage:'/screen/customer.png'} alt='screnn-home' />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='sheep-show-right'>
            {
                design.contentText&&design.contentText.map((i,index)=>{
                  return <div>
                  <span className='yfjs-title-white-14'>{i.title}</span>{i.text}
                </div>
                })
              }
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='sheep-show-bottom'>
        <p>{design.bottomTitle}</p>
        <img src={design.bottomImg1||'/children/design1.png'} alt='1'/>
        <Box className='route-show-bottom-button cusor-point'
          sx={
            {
              width: {
                xs: '300px !important',
              }
            }
          }
          onClick={() => { routerHandler() }}
        >
          申请体验
        </Box>
      </div>
      <div className='sheep-show-footer'>
           <img src={design.bottomImg2||'/children/design2.png'} alt='2'/>
      </div>
      <div className='sheep-show-footer'>
           <img src={design.bottomImg3||'/children/design3.png'} alt='2'/>
      </div>

    </div>

  );
};

export default About;
